var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-inset-text",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.banner.title || "",
          label: "What is the campaign title?",
          help:
            "This text will appear as the large heading on the campaign (70 characters max)",
          error: _vm.errors ? _vm.errors.title : null,
          id: "banner.title"
        },
        on: {
          input: function($event) {
            return _vm.onInput({ field: "title", value: $event })
          }
        }
      }),
      _c("ck-wysiwyg-input", {
        attrs: {
          value: _vm.banner.content || "",
          label: "Campaign description",
          help:
            "This text will appear as the smaller description text on the campaign (200 characters max)",
          error: _vm.errors ? _vm.errors.content : null,
          id: "banner.content"
        },
        on: {
          input: function($event) {
            return _vm.onInput({ field: "content", value: $event })
          }
        }
      }),
      _vm.showImageInput
        ? _c("ck-image-input", {
            attrs: {
              id: "logo",
              label: "Add a logo",
              hint:
                "Click 'Choose file' below to upload a logo to be displayed on the campaign",
              "existing-url": _vm.banner.has_image
                ? _vm.apiUrl("/settings/banner-image.png?v=" + _vm.now)
                : undefined
            },
            on: {
              input: function($event) {
                return _vm.onInput({
                  field: "image_file_id",
                  value: $event.file_id
                })
              }
            }
          })
        : _vm._e(),
      _c("ck-text-input", {
        attrs: {
          value: _vm.banner.button_text || "",
          label: "What should the button say?",
          hint: 'eg. "Find out more" (30 characters max)',
          error: _vm.errors ? _vm.errors.button_text : null,
          id: "banner.button_text"
        },
        on: {
          input: function($event) {
            return _vm.onInput({ field: "button_text", value: $event })
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.banner.button_url || "",
          label: "What page should the campaign link to?",
          hint: "Copy and paste the web address below",
          error: _vm.errors ? _vm.errors.button_url : null,
          id: "banner.button_url",
          type: "url"
        },
        on: {
          input: function($event) {
            return _vm.onInput({ field: "button_url", value: $event })
          }
        }
      }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }